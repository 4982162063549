<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('spadehigh.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title> {{ $t('spadehigh.head_list4') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-select
                  class="px-1"
                    :label="$t('spadehigh.reward_type')"
                    v-model="gameData.reward_type"
                    :rules="[v => !!v || '']"
                    :items="typeItems"
                    item-text="name"
                    dense
                    outlined
                  />
                <v-text-field
                  v-if="gameData.reward_type === 'money'"
                  class="px-1"
                  :label="$t('spadehigh.multiply')"
                  v-model="gameData.multiply_turnover" type="number"
                  :placeholder="$t('spadehigh.multiply')"
                  :rules="rules"
                  dense
                  outlined
                  hide-spin-buttons
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-title> {{ $t('spadehigh.head_list1') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.reward1')" v-model="gameData.reward1" type="number"
                  :placeholder="$t('spadehigh.reward1')" :rules="rules" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.reward2')" v-model="gameData.reward2" type="number"
                  :placeholder="$t('spadehigh.reward2')" :rules="rules" @input="handleInput('reward2', $event)" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.reward3')" v-model="gameData.reward3" type="number"
                  :placeholder="$t('spadehigh.reward3')" :rules="rules" @input="handleInput('reward3', $event)" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.reward4')" v-model="gameData.reward4" type="number"
                  :placeholder="$t('spadehigh.reward4')" :rules="rules" @input="handleInput('reward4', $event)" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.reward5')" v-model="gameData.reward5" type="number"
                  :placeholder="$t('spadehigh.reward5')" :rules="rules" @input="handleInput('reward5', $event)" dense outlined hide-spin-buttons />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-title> {{ $t('spadehigh.head_list2') }} </v-card-title>
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.percent1')" v-model="gameData.percent1" type="number"
                  placeholder="100" disabled dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.percent2')" v-model="gameData.percent2" type="number"
                  :placeholder="$t('spadehigh.percent2')" :rules="rules" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.percent3')" v-model="gameData.percent3" type="number"
                  :placeholder="$t('spadehigh.percent3')" :rules="rules" dense outlined hide-spin-buttons />
              </v-col>
              <v-col cols="12" xs="12" md="6" lg="4">
                <v-text-field class="px-1" :label="$t('spadehigh.percent4')" v-model="gameData.percent4" type="number"
                  :placeholder="$t('spadehigh.percent4')" :rules="rules" dense outlined hide-spin-buttons />
              </v-col>
              <v-text-field class="px-1" :label="$t('spadehigh.percent5')" v-model="gameData.percent5" type="number"
                :placeholder="$t('spadehigh.percent5')" :rules="rules" dense outlined hide-spin-buttons />
              <v-col cols="12" xs="12" md="6" lg="4"></v-col>
            </v-row>
          </v-card-actions>
          <v-card-title> {{ $t('spadehigh.head_list3') }} </v-card-title>
          <v-divider class="px-3" />
          <v-card-actions>
            <v-col cols="12" xs="12" md="6" lg="4" class="px-0">
              <v-col cols="12" class="px-0">
                <v-row no-gutters>
                  <v-col cols="5" xs="5" md="5" lg="5">
                    <v-row no-gutters>
                      <v-checkbox class="mt-1" v-model="isNotLimitPlay" />
                      <span class="mt-2">{{ $t('spadehigh.no_limit_play') }}</span>
                    </v-row>
                  </v-col>
                  <v-col cols="7" xs="7" md="7" lg="7">
                    <v-text-field class="px-1" :label="$t('spadehigh.limit_play')" v-model="gameData.limit_play" type="number"
                      :placeholder="$t('spadehigh.limit_play')" :rules="isNotLimitPlay ? [] : rules" :disabled="isNotLimitPlay" dense outlined hide-spin-buttons />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row no-gutters>
                  <v-col cols="5" xs="5" md="5" lg="5">
                    <v-row no-gutters>
                      <v-checkbox class="mt-1" v-model="isNotLimitGameTime" />
                      <span class="mt-2">{{ $t('spadehigh.no_game_time') }}</span>
                    </v-row>
                  </v-col>
                  <v-col cols="7" xs="7" md="7" lg="7">
                    <v-text-field class="px-1" :label="$t('spadehigh.game_time')" v-model="gameData.game_time" type="number"
                      :placeholder="$t('spadehigh.game_time')" :rules="isNotLimitGameTime ? [] : rules" :disabled="isNotLimitGameTime" dense outlined hide-spin-buttons />
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="saveData" :loading="loading" color="success" class="white--text px-5">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'

export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      formValid: true,
      loading: false,
      gameData: {
        reward1: null,
        reward2: null,
        reward3: null,
        reward4: null,
        reward5: null,
        percent1: 100,
        percent2: null,
        percent3: null,
        percent4: null,
        percent5: null,
        limit_play: null,
        game_time: null,
        reward_type: null,
        multiply_turnover: null,
      },
      isNotLimitPlay: false,
      isNotLimitGameTime: false,
      rules: [
        value => (value !== '' && value !== null && value !== undefined) || this.$t('alert_list.list1'),
        value => /^[0-9]+(\.[0-9]{0,2})?$/.test(value) || this.$t('alert_list.list2'),
      ],
      typeItems: [
        {
          name: 'Point',
          value: 'point',
        },
        {
          name: 'Coin',
          value: 'coin',
        },
        {
          name: 'Money',
          value: 'money',
        },
      ],
    }
  },
  async created() {
    this.addLogPage()
    this.getData()
  },
  watch: {

  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'SpadeHigh',
            url: window.location.href,
            detail: 'ตั้งค่า SpadeHigh',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch('getSpadeHighData', 'name=spadehigh')
        let resData = res
        if(resData && resData.data) {
          this.gameData = resData.data
          this.isNotLimitPlay = this.gameData.limit_play ? false : true
          this.isNotLimitGameTime = this.gameData.game_time ? false : true
        }
      } catch (e) { }
    },
    async saveData() {
      this.loading = true
      if (this.$refs.form.validate()) {
        for (let key in this.gameData) {
          if (this.gameData.hasOwnProperty(key)) {
            let value = this.gameData[key];

            if((key === 'limit_play' && this.isNotLimitPlay) || (key === 'game_time' && this.isNotLimitGameTime)) {
              value = null;
              this.gameData[key] = value;
            }

            if (!isNaN(parseInt(value))) {
              this.gameData[key] = parseInt(value);
            }
          }
        }

        try {
          let res = await this.$store.dispatch('saveSpadeHighData', this.gameData)
          this.$swal.fire(this.$t("update_success"), '', 'success')
        } catch (e) { }
      }
      this.loading = false
    },
    handleInput(fieldName, event) {
      let newValue = event;

      // Remove non-digit characters except for dot (.)
      newValue = newValue.replace(/[^\d.]/g, '');

      // Limit to two digits after the dot
      // console.log(newValue)
      const dotIndex = newValue.indexOf('.');
      let moreThanTwoDigits = false
      if (dotIndex !== -1) {
        const digitsAfterDot = newValue.length - dotIndex - 1;
        // console.log(digitsAfterDot)
        if (digitsAfterDot > 2) {
          moreThanTwoDigits = true;
          newValue = newValue.slice(0, dotIndex + 3);
        }
      }

      // Update the value in gameData object
      // console.log(moreThanTwoDigits)
      if(!moreThanTwoDigits){
        this.gameData[fieldName] = newValue;
      }
    },
  },
}
</script>
